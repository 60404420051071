import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/logoSVG.svg'
import { CgFileDocument } from "react-icons/cg";
import { useState } from 'react';
import { Modal} from 'react-bootstrap';


function Navbars() {

  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <>
    <Navbar bg="bg-xcrow" expand="lg" variant="dark">
      <Container >
        <Navbar.Brand href="#">
          <img src={logo} alt='logo'
            className='adjust-img1'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 "
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link className='navlinkNormal' href="#About">About</Nav.Link>
            <Nav.Link className='navlinkNormal' href="https://docs.xcrow.net/docs/FAQS/General" target="_blank" rel="noopener noreferrer">FAQ</Nav.Link>
            <Nav.Link className='navlinkNormal' href="#Contact">Contact</Nav.Link>
            <Nav.Link className='navlinkNormal linkMobile' href='https://docs.xcrow.net' target="_blank" rel="noopener noreferrer">Documents</Nav.Link>
          </Nav>
      
   
          <Nav.Link title="Documentation" className='me-4 text-light navlinkDoc' href='https://docs.xcrow.net' target="_blank" rel="noopener noreferrer">
          <CgFileDocument className='adjust-img' />
              Documentation
          
              </Nav.Link>
           
          {/* <Nav.Link href="/docs" className='me-4 text-light navlinkDoc'>
            <CgFileDocument className='adjust-img' />
            Documentation
          </Nav.Link> */}

          <div className='nest-button ' onClick={handleShow} >
          {/* <a class="dropdown-item" href="https://drive.google.com/file/d/1hd4hf-uYosKOWlEJGh6StSbEop8bAQWI/view?usp=sharing" target="_blank">Whitepaper</a> */}
            Start Nesting
          
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Modal show={show} onHide={handleClose}className='modal' >
                <Modal.Header closeButton className='modalHeader'>
                    <Modal.Title className='modalHeader'>MVP will be available soon!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalBg'>For more information please join our community at discord and follow us on social media.</Modal.Body>
               
            </Modal>
            

            
    </>
  );
}

export default Navbars;