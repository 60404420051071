
import './index.css';
import Navbars from './components/Navbars';
import ThirdSection from './components/ThirdSection';
import FourthSection from './components/FourthSection';
import FifthSection from './components/FifthSection';
import SixthSection from './components/SixthSection';
import Tokendistr from './components/Tokendistr';
import Team from './components/Team';
import Newsletter from './components/Newsletter';
import FirstSection from './components/FirstSection';
import React from 'react';
import Footer from './components/Footer';




function App() {

  
  return (


    <div className="App">
      <Navbars />
      <FirstSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <Tokendistr />
      <Team />
      <Newsletter />
     <Footer/>
    </div>
  );
}

export default App;
